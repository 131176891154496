import { getRefreshedAbility } from './ability';

export const canNavigate = to => {
  const refreshedAbilities = getRefreshedAbility()
  
  const getIsAllowedRoute = (route) => {
    return route.meta.resource ? 
        refreshedAbilities.can(route.meta.action || 'read', route.meta.resource)
      : true
  }
  return to.matched.some(getIsAllowedRoute);
}

export const _ = undefined;
