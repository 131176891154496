import { checkMediSaludRole } from '@/auth/utils';

export default [{
        path: '/medisalud/forms',
        name: 'medisalud-forms',
        component: () => import ('@/views/modules/medisalud/forms/forms-list/FormsList.vue'),
        beforeEnter: checkMediSaludRole,
        meta: {
            resource: 'medisalud',
            pageTitle: 'Formularios',
            breadcrumb: [{
                text: 'Lista de formularios',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/medisalud/form/:id/edit',
        name: 'medisalud-form-edit',
        component: () => import ('@/views/modules/medisalud/forms/forms-edit/FormsEdit.vue'),
        beforeEnter: checkMediSaludRole,
        meta: {
            resource: 'medisalud',
            pageTitle: 'Revisar Formulario',
            requiresAuth: true
        }
    }
]