import Vue from 'vue';

// axios
import axios from 'axios';

const axiosIns = axios.create({
  	// You can add your headers here
  	// ================================
  	baseURL: `${process.env.VUE_APP_HOST}/api/dashboard`,
  	headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
