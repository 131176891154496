import { checkAdminRole } from '@/auth/utils';

export default [
    {
        path: '/revisers',
        name: 'revisers',
        component: () => import('@/views/modules/revisers/revisers-list/RevisersList.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            resource: 'admin',
            pageTitle: 'Revisores',
            breadcrumb: [
                {
                    text: 'Lista de revisores',
                    active: true
                }
            ],
            requiresAuth: true
        }
    },
    {
        path: '/revisers/:id/edit',
        name: 'revisers-edit',
        component: () => import('@/views/modules/revisers/revisers-edit/RevisersEdit.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            resource: 'admin',
            pageTitle: 'Revisores',
            breadcrumb: [
                {
                    text: 'Editar revisor',
                    active: true
                }
            ],
            requiresAuth: true
        }
    }
]