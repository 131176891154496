export default [
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => import('@/views/pages/authentication/ResetPassword.vue'),
        meta: {
            layout: 'full',
            redirectIfLoggedIn: true
        }
    }
]