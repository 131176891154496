import { checkAdminRole } from '@/auth/utils';

export default [{
        path: '/providers',
        name: 'providers',
        component: () => import ('@/views/modules/providers/providers-list/ProvidersList.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            resource: 'admin',
            pageTitle: 'Proveedores',
            breadcrumb: [{
                text: 'Lista de proveedores',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/providers/:id/edit',
        name: 'providers-edit',
        component: () => import ('@/views/modules/providers/providers-edit/ProvidersEdit.vue'),
        beforeEnter: checkAdminRole,
        meta: {
            resource: 'admin',
            pageTitle: 'Proveedores',
            breadcrumb: [{
                text: 'Editar proveedor',
                active: true
            }],
            requiresAuth: true
        }
    }
]