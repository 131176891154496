export default [
    {
        path: '/opt/:token',
        name: 'opt-verification',
        component: () => import('@/views/pages/authentication/OptVerification.vue'),
        meta: {
            layout: 'full',
            redirectIfLoggedIn: true
        }
    }
]