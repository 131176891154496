import { checkReviserRole } from '@/auth/utils';

export default [{
        path: '/reviser/forms',
        name: 'reviser-forms',
        component: () => import ('@/views/modules/reviser/forms/forms-list/FormsList.vue'),
        beforeEnter: checkReviserRole,
        meta: {
            resource: 'revisers',
            pageTitle: 'Formularios',
            breadcrumb: [{
                text: 'Lista de formularios',
                active: true
            }],
            requiresAuth: true
        }
    },
    {
        path: '/reviser/form/:id/edit',
        name: 'reviser-form-edit',
        component: () => import ('@/views/modules/reviser/forms/forms-edit/FormsEdit.vue'),
        beforeEnter: checkReviserRole,
        meta: {
            resource: 'revisers',
            pageTitle: 'Revisar Formulario',
            requiresAuth: true
        }
    }
]