const file = process.env.VUE_APP_OPT_SECURITY_CODE === 'true' ? 'Login.vue' : 'Login2.vue';

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import(`@/views/pages/authentication/${file}`),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true
        }
    }
]