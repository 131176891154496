export default [
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            redirectIfLoggedIn: true
        }
    }
]